<template>
  <span>
    <GeneralDialog :visible.sync="isShowDialog" @close="onClose">
      <div class="dialog-content">
        <div>
          <i class="el-icon-info icon mb-2"></i>
          <div class="text-bold mb-3">{{ $t('promotion.fgp.optOut') }}</div>
          <div>{{ $t('promotion.fgp.dialog.optOutContext') }}</div>
        </div>
      </div>
      <el-form :model="formData" :rules="rules" ref="form">
        <el-form-item :label="$t('common.field.accNum')" prop="selectedAccounts">
          <el-select
            v-model="formData.selectedAccounts"
            :placeholder="$t('promotion.fgp.dialog.selectAccPlaceholder')"
            multiple
          >
            <el-option
              v-for="item in activeAccounts"
              :key="item.mt4Account"
              :label="item.mt4Account"
              :value="item.mt4Account"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div class="btn-wrapper">
          <el-button class="outline-btn submit-btn" @click="onSubmit">
            {{ $t('common.button.confirm') }}
          </el-button>
          <el-button class="blue-btn submit-btn" @click="onClose">
            {{ $t('common.button.cancel') }}
          </el-button>
        </div>
      </el-form>
    </GeneralDialog>

    <!-- Result Dialog -->
    <ResultDialog :dialogVisible.sync="resultDialogVisible" resultModalType="optOut" />
  </span>
</template>

<script>
import GeneralDialog from '@/components/dialog/general/Dialog.vue';
import ResultDialog from '@/components/promotion/fundsGrowthPromo/ResultDialog.vue';
import { apiOptOutWithAccounts } from '@/resource';

export default {
  name: 'OptOutDialog',
  components: { GeneralDialog, ResultDialog },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    campaignID: {
      type: Number,
      required: true
    },
    activeAccounts: {
      type: Array
    }
  },
  data() {
    return {
      rules: {
        selectedAccounts: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ]
      },
      formData: {
        selectedAccounts: []
      },
      isShowDialog: false,
      resultDialogVisible: false
    };
  },
  watch: {
    dialogVisible: {
      immediate: true,
      handler(newValue) {
        this.isShowDialog = newValue;
      }
    }
  },
  methods: {
    showError() {
      this.$message({
        message: this.$t('common.field.failed'),
        type: 'error',
        duration: 5000
      });
    },
    onClose() {
      this.$refs.form.resetFields();
      this.$emit('update:dialogVisible', false);
    },
    getRequestBody(accounts) {
      const reqBody = {
        campaignId: this.campaignID,
        optIn: false,
        accountMt4DtoList: accounts.map(acc => {
          return {
            mt4Account: acc,
            datasourceId: this.activeAccounts.find(i => i.mt4Account === acc).datasourceId
          };
        })
      };
      return reqBody;
    },
    validateForm() {
      return new Promise(resolve => {
        this.$refs.form.validate(valid => {
          resolve(valid);
        });
      });
    },
    async onSubmit() {
      try {
        const isValid = await this.validateForm();
        if (!isValid) return;

        const reqBody = this.getRequestBody(this.formData.selectedAccounts);
        apiOptOutWithAccounts(reqBody).then(resp => {
          if (resp.data.data) {
            this.onClose();
            this.resultDialogVisible = true;
          } else {
            this.showError();
          }
        });
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/promotion/fundsGrowthPromo/accountDialog.scss';

.text-bold {
  font-weight: bolder;
}

.icon {
  font-size: 50px;
  color: $pug-blue;
}

/deep/.dialog.el-dialog__wrapper .el-dialog.el-dialog--center {
  width: 400px !important;
}

@media (max-width: 500px) {
  /deep/.dialog.el-dialog__wrapper .el-dialog.el-dialog--center {
    width: auto !important;
  }
}
</style>
