<template>
  <div class="banner-container">
    <div class="new-banner-image" :style="{ backgroundImage: `url(${bannerUrl})` }">
      <div class="bn-info">
        <h3>
          <span>
            <span class="title">{{ $t('promotion.fgp.banner.title') }} <span class="border_blue">13% p.a.</span> </span
            ><br /><br />
            <span class="desc mt-5">{{ $t('promotion.fgp.banner.desc') }}</span
            ><br />
            <span class="desc">{{ $t('promotion.fgp.banner.desc2') }}</span>
          </span>
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FgpBanner',
  data() {
    return {
      bannerSize: 'md'
    };
  },
  computed: {
    bannerUrl() {
      return require(`@/assets/images/banner/fundsGrowthPromo/banner_${this.bannerSize}.png`);
    },
    bannerLang() {
      return this.$store.state.common.lang;
    }
  },
  methods: {
    handleResize() {
      if (window.innerWidth > 1550) this.bannerSize = 'lg';
      else if (window.innerWidth < 500) this.bannerSize = 'sm';
      else this.bannerSize = 'md';
    }
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  object-fit: cover;
}
</style>
