<template>
  <el-collapse id="faqsTab" v-model="activeCollapses">
    <el-collapse-item v-for="(item, index) in collapses" :key="item.key" :name="item.key">
      <template #title> {{ index + 1 }}.&nbsp;{{ $t(item.title) }} </template>
      <div v-for="(child, child_index) in item.desc" :key="index + child_index" class="question_class mb-4">
        <div class="mt-2" v-for="(answ, ans_index) in child.answers" :key="index + child_index + ans_index">
          <div class="my-1 font_normal">
            <div v-if="index === 4">
              <p>{{ $t('promotion.fgp.faq.fifth.answer1') }}</p>
              <p class="mt-2">{{ $t('promotion.fgp.faq.fifth.answer2') }}</p>
              <div>
                <p class="mt-2 mb-3">{{ $t('promotion.fgp.faq.fifth.answer3') }}</p>
                <el-table :data="tableData1" class="head-gutter mb-3" :span-method="arraySpanMethod" size="mini" border>
                  <el-table-column align="center" :label="$t('promotion.fgp.faq.day')">
                    <template slot-scope="scope"> {{ $t(scope.row.day) }} </template>
                  </el-table-column>
                  <el-table-column align="center" :label="$t('promotion.fgp.faq.funds')">
                    <template slot-scope="scope">
                      {{ scope.row.funds }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    :label="$t('promotion.fgp.faq.fundsLot', { lots: 1 })"
                    min-width="150px"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.fundsLot }}
                    </template>
                  </el-table-column>
                  <el-table-column align="center" :label="$t('promotion.fgp.faq.payout')">
                    <template slot-scope="scope">
                      {{ scope.row.payout }}
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div>
                <p class="mt-2 mb-3">{{ $t('promotion.fgp.faq.fifth.answer4') }}</p>
                <el-table :data="tableData2" class="head-gutter mb-3" :span-method="arraySpanMethod" size="mini" border>
                  <el-table-column align="center" :label="$t('promotion.fgp.faq.day')">
                    <template slot-scope="scope">
                      {{ $t(scope.row.day) }}
                    </template>
                  </el-table-column>
                  <el-table-column align="center" :label="$t('promotion.fgp.faq.funds')">
                    <template slot-scope="scope">
                      {{ scope.row.funds }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    :label="$t('promotion.fgp.faq.fundsLot', { lots: 2 })"
                    min-width="150px"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.fundsLot }}
                    </template>
                  </el-table-column>
                  <el-table-column align="center" :label="$t('promotion.fgp.faq.payout')">
                    <template slot-scope="scope">
                      {{ scope.row.payout }}
                    </template>
                  </el-table-column>
                </el-table>
              </div>

              <div>
                <p class="mt-2 mb-3">{{ $t('promotion.fgp.faq.fifth.answer5') }}</p>
                <el-table :data="tableData3" class="head-gutter" :span-method="arraySpanMethod" size="mini" border>
                  <el-table-column align="center" :label="$t('promotion.fgp.faq.day')">
                    <template slot-scope="scope">
                      {{ $t(scope.row.day) }}
                    </template>
                  </el-table-column>
                  <el-table-column align="center" :label="$t('promotion.fgp.faq.funds')">
                    <template slot-scope="scope">
                      {{ scope.row.funds }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    :label="$t('promotion.fgp.faq.fundsLot', { lots: 2 })"
                    min-width="150px"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.fundsLot }}
                    </template>
                  </el-table-column>
                  <el-table-column align="center" :label="$t('promotion.fgp.faq.payout')">
                    <template slot-scope="scope">
                      {{ scope.row.payout }}
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div v-else>
              <p v-html="$t(answ.key)"></p>
              <div class="mt-2" v-if="answ.examples && answ.examples.length > 0">
                <ul class="mb-2">
                  <li v-for="ex in answ.examples" :key="ex.key">
                    {{ $t(ex.key) }}
                    <div v-if="ex.bullet && ex.bullet.length > 0">
                      <p v-for="bt in ex.bullet" :key="bt.key" class="ml-5 mt-2">
                        {{ $t(bt.key) }}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <p v-html="$t('promotion.fgp.faq.sixth.answer4')" v-if="index === 5" class="mt-3"></p>
            </div>
          </div>
        </div>
      </div>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
export default {
  name: 'Faq',
  data() {
    return {
      activeCollapses: ['1'],
      collapses: [
        {
          key: '1',
          title: 'promotion.fgp.faq.first.title',
          desc: [
            {
              answers: [{ key: 'promotion.fgp.faq.first.answer1' }]
            }
          ]
        },
        {
          key: '2',
          title: 'promotion.fgp.faq.second.title',
          desc: [
            {
              answers: [{ key: 'promotion.fgp.faq.second.answer1' }, { key: 'promotion.fgp.faq.second.answer2' }]
            }
          ]
        },
        {
          key: '3',
          title: 'promotion.fgp.faq.third.title',
          desc: [
            {
              answers: [
                { key: 'promotion.fgp.faq.third.answer1' },
                { key: 'promotion.fgp.faq.third.answer2' },
                { key: 'promotion.fgp.faq.third.answer3' }
              ]
            }
          ]
        },
        {
          key: '4',
          title: 'promotion.fgp.faq.fourth.title',
          desc: [
            {
              answers: [{ key: 'promotion.fgp.faq.fourth.answer1' }, { key: 'promotion.fgp.faq.fourth.answer2' }]
            }
          ]
        },
        {
          key: '5',
          title: 'promotion.fgp.faq.fifth.title',
          desc: [
            {
              answers: [{ key: 'promotion.fgp.faq.fifth.answer1' }]
            }
          ]
        },
        {
          key: '6',
          title: 'promotion.fgp.faq.sixth.title',
          desc: [
            {
              answers: [
                {
                  key: 'promotion.fgp.faq.sixth.answer1',
                  examples: [
                    {
                      key: 'promotion.fgp.faq.sixth.answer2',
                      bullet: [
                        { key: 'promotion.fgp.faq.sixth.bullet1' },
                        { key: 'promotion.fgp.faq.sixth.bullet2' },
                        { key: 'promotion.fgp.faq.sixth.bullet3' },
                        { key: 'promotion.fgp.faq.sixth.bullet4' }
                      ]
                    },
                    { key: 'promotion.fgp.faq.sixth.answer3' }
                  ]
                }
              ]
            }
          ]
        }
      ],
      tableData1: [
        { day: 'Mon', funds: '$18,000', fundsLot: '$5,000', payout: '$2.5' },
        { day: 'Tue', funds: '$18,000', fundsLot: '$5,000', payout: '$2.5' },
        { day: 'Wed', funds: '$18,000', fundsLot: '$5,000', payout: '$2.5' },
        { day: 'Thu', funds: '$18,000', fundsLot: '$5,000', payout: '$2.5' },
        { day: 'Fri', funds: '$18,000', fundsLot: '$5,000', payout: '$2.5' },
        { day: 'promotion.fgp.faq.total', payout: '$12.50' }
      ],
      tableData2: [
        { day: 'Mon', funds: '$18,000', fundsLot: '$10,000', payout: '$5.00' },
        { day: 'Tue', funds: '$18,000', fundsLot: '$10,000', payout: '$5.00' },
        { day: 'Wed', funds: '$18,000', fundsLot: '$10,000', payout: '$5.00' },
        { day: 'Thu', funds: '$18,000', fundsLot: '$10,000', payout: '$5.00' },
        { day: 'Fri', funds: '$18,000', fundsLot: '$10,000', payout: '$5.00' },
        { day: 'promotion.fgp.faq.total', payout: '$25.00' }
      ],
      tableData3: [
        { day: 'Mon', funds: '$8,000', fundsLot: '$8,000', payout: '$4' },
        { day: 'Tue', funds: '$8,000', fundsLot: '$8,000', payout: '$4' },
        { day: 'Wed', funds: '$8,000', fundsLot: '$8,000', payout: '$4' },
        { day: 'Thu', funds: '$8,000', fundsLot: '$8,000', payout: '$4' },
        { day: 'Fri', funds: '$8,000', fundsLot: '$8,000', payout: '$4' },
        { day: 'promotion.fgp.faq.total', payout: '$20.00' }
      ]
    };
  },
  methods: {
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      // Merge the first three columns in the last row
      if (rowIndex === 5) {
        if (columnIndex === 0) {
          return [1, 3]; // Merge first three columns
        } else if (columnIndex > 0 && columnIndex < 3) {
          return [0, 0]; // Skip rendering these cells
        }
      }
      return [1, 1]; // Default behavior for other rows
    }
  }
};
</script>

<style lang="scss" scoped>
#faqsTab /deep/ {
  border: none;
  .el-collapse-item {
    &:not(:last-child) {
      .el-collapse-item__header {
        border-bottom: 1px solid #ebeef5;
      }
    }
    .el-collapse-item__header {
      border-bottom: 1px solid #ebeef5;
    }

    .el-collapse-item__header {
      color: #232020;
      font-size: 16px;
      font-weight: 500;
      padding: 0 24px;
    }

    .el-collapse-item__content {
      color: #232020;
      font-size: 16px;
      border: none;
      padding: 22px 24px 14px;
    }

    .el-collapse-item__wrap {
      &:not(:last-child) {
        border-bottom: 1px solid #ebeef5;
      }
    }

    .el-collapse-item__arrow {
      font-weight: bold;
      transform: rotate(90deg);
      &.is-active {
        transform: rotate(-90deg);
      }
    }
  }
  .ml-5 {
    margin-left: 25px;
  }
}
.question_class {
  font-weight: bold;
}
.font_normal {
  font-weight: normal;
}

ul,
li {
  list-style: disc;
  list-style-position: inside;
}

/deep/.el-collapse-item__header {
  height: auto;
  line-height: 1.5;
  padding: 12px 0 !important;
}
/deep/.el-collapse-item__content {
  padding: 12px 0 !important;
}
</style>
