<template>
  <div>
    <!-- Top Page Banner -->
    <FgpBanner />
    <!-- Before Opt In -->
    <FundAccount v-if="!optIn" :campaignID="campaignID" />
    <!-- After Opt In -->
    <WeeklyAchievement
      v-else
      :optIn="optIn"
      :activeAccounts="activeAccounts"
      :selectedActiveAccount.sync="selectedActiveAccount"
      :campaignID="campaignID"
    />
    <!-- History / Faq -->
    <div class="bottom-box meta-box mb-5">
      <el-tabs class="fgp_tabs">
        <el-tab-pane :label="$t('promotion.fgp.historyTitle')">
          <History :optIn="optIn" :campaignID="campaignID" />
        </el-tab-pane>
        <el-tab-pane :label="$t('promotion.fgp.faqs')">
          <el-card shadow="never"><Faq /></el-card>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import FgpBanner from '@/components/promotion/fundsGrowthPromo/FgpBanner.vue';
import FundAccount from '@/components/promotion/fundsGrowthPromo/FundAccount.vue';
import Faq from '@/components/promotion/fundsGrowthPromo/Faq.vue';
import History from '@/components/promotion/fundsGrowthPromo/FgpHistory.vue';
import BannerItemV2 from '@/components/banner/item/v2/BannerItem.vue';
import WeeklyAchievement from '@/components/promotion/fundsGrowthPromo/WeeklyAchievement.vue';
import { PROMOTION_TYPE } from '@/constants/promotion';
import { apiCampaignInfo } from '@/resource';

export default {
  name: 'FundsGrowthPromo',
  components: {
    FgpBanner,
    FundAccount,
    BannerItemV2,
    Faq,
    History,
    WeeklyAchievement
  },
  data() {
    return {
      optIn: false,
      activeAccounts: [],
      selectedActiveAccount: undefined,
      resultDialogVisible: false
    };
  },
  computed: {
    campaignID() {
      let campaign =
        this.$store.state.promotion.eligibleCampaigns.find(c => c.campaignType === PROMOTION_TYPE.FUNDS_GROWTH_PROMO) ||
        {};
      if (!campaign.id) {
        this.$router.push('/404');
        return null;
      }
      return campaign.id;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getCampaignData();
    },
    getCampaignData() {
      apiCampaignInfo(this.campaignID).then(resp => {
        if (resp.data.code === 0 && resp.data.data && resp.data.data.participants.length > 0) {
          const isOptInAccounts = resp.data.data.participants.filter(x => x.isOptIn === true);
          if (isOptInAccounts.length > 0) {
            this.optIn = true;
            this.activeAccounts = isOptInAccounts;
          }
          this.selectedActiveAccount = this.activeAccounts[0].mt4Account;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/fgp.scss';
</style>
