<template>
  <el-row type="flex" align="middle">
    <el-date-picker
      :picker-options="startDateOption"
      v-model="startDateValue"
      type="date"
      value-format="yyyy-MM-dd"
      :clearable="clearable"
      data-testid="startDate"
      @change="onPickerChange"
    >
    </el-date-picker>
    <div class="calendar-to">-</div>
    <el-date-picker
      :picker-options="endDateOption"
      v-model="endDateValue"
      type="date"
      value-format="yyyy-MM-dd"
      :clearable="clearable"
      data-testid="endDate"
      @change="onPickerChange"
    >
    </el-date-picker>
  </el-row>
</template>

<script>
import moment from 'moment';

export default {
  name: 'DatePicker',
  props: {
    startDate: {
      type: String,
      default: function () {
        return moment(new Date()).format('YYYY-MM-DD');
      }
    },
    endDate: {
      type: String,
      default: function () {
        return moment(new Date()).format('YYYY-MM-DD');
      }
    },
    startDateOption: {
      type: Object,
      default: function () {
        return {};
      }
    },
    endDateOption: {
      type: Object,
      default: function () {
        return {};
      }
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      startDateValue: this.startDate,
      endDateValue: this.endDate
    };
  },
  watch: {
    startDate(newVal) {
      this.startDateValue = newVal;
    },
    endDate(newVal) {
      this.endDateValue = newVal;
    }
  },
  methods: {
    onPickerChange() {
      this.$emit('change', [this.startDateValue, this.endDateValue]);
    }
  }
};
</script>

<style lang="scss" scoped>
.calendar-to {
  margin: 5px;
}
</style>
