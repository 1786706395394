<template>
  <el-dialog :visible.sync="dialogVisible" :before-close="handleClose">
    <div class="text-center">
      <i class="el-icon-success icon text-green mb-1"></i>
      <div class="text-green text-bold mb-3">{{ $t('promotion.fgp.dialog.successfully') }}</div>
      <span v-if="resultModalType === 'optIn'">
        <div class="mb-3 success-text">{{ $t('promotion.fgp.dialog.successOptInText') }}</div>
        <a :href="tncLink" class="tnc" data-testid="tnc" target="_blank">
          {{ $t('promotion.fgp.tncLink') }}
        </a>
      </span>
      <span v-if="resultModalType === 'optOut'">
        <div class="success-text">{{ $t('promotion.fgp.dialog.successOptOutText') }}</div>
      </span>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'resultDialog',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    resultModalType: {
      type: String,
      default: 'optIn'
    }
  },
  data() {
    return {
      tncLink: 'https://www.puprime.com/terms-and-conditions/13-pa-funds-growth/'
    };
  },
  watch: {
    dialogVisible: {
      immediate: true,
      handler(newValue) {
        this.isShowDialog = newValue;
      }
    }
  },
  methods: {
    handleClose() {
      this.$emit('update:dialogVisible', false);
      location.reload();
    }
  }
};
</script>

<style lang="scss" scoped>
.text-center {
  text-align: center;
  padding: 20px 30px 50px 30px;
}

.icon {
  font-size: 50px;
}

.text-green {
  color: $pug-green-success;
}

.text-bold {
  font-weight: bolder;
}

.success-text {
  word-break: normal;
}

.tnc {
  color: $pug-blue;
  text-decoration: underline;
}

/deep/.el-dialog {
  width: 340px !important;
}

@media (max-width: 400px) {
  /deep/.el-dialog {
    width: auto !important;
  }
}
</style>
