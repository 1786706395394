<template>
  <div class="table-container">
    <!-- table title -->
    <div class="filter-header mt-2 mb-4">
      <DateRangePicker
        :startDate.sync="startDate"
        :endDate.sync="endDate"
        :startDateOption="startDateOption"
        :endDateOption="endDateOption"
        :clearable="false"
        @change="onPickerChange"
        class="item"
      />
      <el-button class="item update-btn btn-blue" @click="getHistoryData">{{ $t('promotion.fgp.update') }}</el-button>
    </div>

    <!-- table -->
    <el-row :gutter="20">
      <el-col :span="24">
        <div>
          <el-table :data="displayData" class="head-gutter" header-row-class-name="header-class">
            <el-table-column align="center" :label="$t('promotion.fgp.history.date')" min-width="165">
              <template slot-scope="scope">
                {{ scope.row.settlementDate }}
              </template>
            </el-table-column>
            <el-table-column align="center" :label="$t('promotion.fgp.history.tradingAcc')" min-width="165">
              <template slot-scope="scope">
                {{ scope.row.tradingAccount }}
              </template>
            </el-table-column>
            <el-table-column align="center" :label="$t('promotion.fgp.history.currency')" min-width="165">
              <template slot-scope="scope">
                {{ scope.row.currency }}
              </template>
            </el-table-column>
            <el-table-column align="center" :label="$t('promotion.fgp.history.weeklyInterest')" min-width="165">
              <template slot-scope="scope">
                {{ scope.row.interest }}
              </template>
            </el-table-column>
            <el-table-column align="center" :label="$t('promotion.fgp.history.invalid')" min-width="165">
              <template slot-scope="scope">
                <span
                  >{{
                    scope.row.failReason.length > 0
                      ? $t(`promotion.fgp.history.invalidReason.${scope.row.failReason[0]}`)
                      : '--'
                  }}
                </span>
                <el-popover
                  v-if="scope.row.failReason.length > 1"
                  placement="top-start"
                  trigger="hover"
                  :content="getConcatenatedFailReasons(scope.row.failReason)"
                >
                  <span slot="reference" class="ellipsis">...</span>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
          <Pagination :table-data="tableData" :display-data.sync="displayData"></Pagination>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue';
import { apiGetFgpHistory } from '@/resource';
import DateRangePicker from './DateRangePicker.vue';
import moment from 'moment';

export default {
  name: 'FgpHistory',
  components: { Pagination, DateRangePicker },
  props: {
    optIn: { type: Boolean },
    campaignID: { type: Number }
  },
  data() {
    const startDateString = moment().subtract(1, 'months').format('YYYY-MM-DD');
    const endDateString = moment().format('YYYY-MM-DD');
    return {
      displayData: [],
      pageSize: 10,
      tableData: [],
      startDate: startDateString,
      endDate: endDateString,
      startDateOption: {
        disabledDate(time) {
          return moment(time) > moment();
        }
      },
      endDateOption: {
        disabledDate(time) {
          return moment(time) > moment() || moment(time) < moment(startDateString);
        }
      }
    };
  },
  watch: {
    optIn() {
      this.getHistoryData();
    }
  },
  methods: {
    getHistoryData() {
      if (this.optIn) {
        apiGetFgpHistory(this.campaignID, this.startDate, this.endDate).then(resp => {
          if (resp.data.code === 0 && resp.data.data) {
            this.tableData = [];
            resp.data.data.forEach(acc => {
              acc.history.forEach(x => {
                this.tableData.push({
                  settlementDate: moment(x.settlementDate).format('DD-MM-YYYY'),
                  tradingAccount: acc.mt4Account,
                  currency: acc.currency,
                  interest:
                    x.interest !== null
                      ? '$' + x.interest.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                      : '--',
                  failReason: x.failReasons
                });
              });
            });

            this.tableData = this.tableData.sort(
              (a, b) => moment(b.settlementDate, 'DD-MM-YYYY') - moment(a.settlementDate, 'DD-MM-YYYY')
            );
          }
        });
      } else {
        this.tableData = [];
      }
    },
    getConcatenatedFailReasons(data) {
      const reasons = data.slice(1);
      return reasons.map(x => this.$t(`promotion.fgp.history.invalidReason.${x}`)).join('; ');
    },
    onPickerChange(val) {
      const start = val[0];
      const end = val[1];
      const isStartLaterThanEnd = moment(start) > moment(end);
      this.startDate = start;
      this.endDate = isStartLaterThanEnd ? start : end;
      this.endDateOption = {
        disabledDate(time) {
          return moment(time) > moment() || moment(time) < moment(start);
        }
      };
    }
  },
  mounted() {
    this.getHistoryData();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/promotion/blueHeadTable.scss';
.table-title.with-account {
  margin-bottom: 16px;
}

.filter-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  gap: 10px;

  .item {
    flex: 0 1 auto;
  }

  .update-btn {
    padding: 12px 20px;
  }
}

/deep/.header-class {
  background-color: black !important;
}

/deep/.el-table.head-gutter th {
  padding: 0 5px 0 0;

  &:nth-last-child(1) {
    padding: 0;
  }
}

/deep/.el-date-editor .el-input__inner {
  height: auto;
}

@media (max-width: 530px) {
  .filter-header {
    .item {
      flex: 1 1 100%;
    }
  }
}
</style>
