<template>
  <div class="meta-box">
    <div class="container">
      <h3>{{ $t('promotion.fgp.title') }}</h3>
      <!-- action btn -->
      <el-row :gutter="20" class="sub-box1">
        <el-col :xs="24">
          <p class="desc-title text-center" v-html="$t('promotion.fgp.desc')"></p>
        </el-col>
        <el-col :xs="24" class="button-box opt-button-box">
          <el-button @click="onActivateCampaign" class="mb-4 el-button btn-blue long-button" data-testid="opt">
            {{ $t('promotion.fgp.buttonTitle') }}
          </el-button>
        </el-col>
        <el-col :xs="24" class="image-box">
          <img :src="imagePath" alt />
        </el-col>
      </el-row>

      <!-- description -->
      <el-row :gutter="20" class="mt-3 sub-box2">
        <el-col :xs="24">
          <!-- eligibility -->
          <Description
            v-if="eligibilityTitle?.length > 0"
            :title="$t(eligibilityTitle)"
            :descriptions="eligibilityDescriptions"
          >
            <slot name="eligibilityDescriptions" />
          </Description>
        </el-col>
        <el-col :xs="24">
          <!-- notes -->
          <Description v-if="notesTitle?.length > 0" :title="$t(notesTitle)" :descriptions="notesDescriptions">
            <slot name="eligibilityDescriptions" />
          </Description>
        </el-col>

        <el-col :xs="24" class="agreeTnc-box text-center">
          <i18n path="promotion.fgp.tnc">
            <template v-slot:link>
              <a :href="tncLink" data-testid="tnc" target="_blank" class="blue-text">
                {{ $t('promotion.fgp.link') }}
              </a>
            </template>
          </i18n>
        </el-col>
      </el-row>
      <OptInDialog :dialogVisible.sync="showAccountDialog" :campaignID="campaignID" accountModalType="optInNew" />
    </div>
  </div>
</template>

<script>
import Description from '@/components/promotion/fundsGrowthPromo/Description.vue';
import OptInDialog from './OptInDialog.vue';

export default {
  name: 'FundAccount',
  components: {
    Description,
    OptInDialog
  },
  props: {
    campaignID: {
      type: Number,
      default: 46
    }
  },
  mixins: [],
  data() {
    return {
      showAccountDialog: false,
      showAgreeTnc: false,
      claimTitle: this.$t('promotion.fgp.claimTitle'),
      claimDescriptions: this.$t('promotion.fgp.claimDescriptions'),
      eligibilityTitle: this.$t('promotion.fgp.eligibilityTitle'),
      eligibilityDescriptions: Object.values(this.$t('promotion.fgp.eligibleDescriptions')),
      notesTitle: this.$t('promotion.fgp.notesTitle'),
      notesDescriptions: Object.values(this.$t('promotion.fgp.notesDescriptions')),
      imagePath: null,
      tncLink: 'https://www.puprime.com/terms-and-conditions/13-pa-funds-growth/'
    };
  },
  computed: {
    bannerLang() {
      return this.$store.state.common.lang;
    }
  },
  mounted() {
    this.handleResize();
    // Add event listener when the component is mounted
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    // Remove event listener before the component is destroyed
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      if (window.innerWidth < 500) {
        this.imagePath = require(`@/assets/images/promotion/fundsGrowthPromo/fgp_mobile_${this.bannerLang}.png`);
      } else {
        this.imagePath = require(`@/assets/images/promotion/fundsGrowthPromo/fgp_pc_${this.bannerLang}.png`);
      }
    },
    onActivateCampaign() {
      this.showAccountDialog = true;
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/pages/fgp.scss';
.container {
  padding: 50px;
  background-color: $white;
  border-radius: 10px;
}

.image-box {
  margin-top: 20px;
  margin-bottom: 60px;
  text-align: center;
  img {
    width: 100%;
  }
}

.text-center {
  text-align: center;
}

.blue-text {
  color: $pug-blue;
}

/deep/ {
  .btn-box {
    margin-top: 65px;
    text-align: center;
  }
  .el-button {
    min-width: 0 !important;
    font-size: 15px;
    font-weight: 900;
    text-align: center;
    background-color: $future-blue;
    color: white;
    &.is-disabled {
      background-color: $future-blue;
      color: white;
    }
  }
}

@media (max-width: 480px) {
  .image-box {
    img {
      width: 100%;
    }
  }
}

.top {
  .header-text {
    font-weight: 800;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 20px;
  }
  ol {
    li {
      font-weight: normal;
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 21px;
    }
  }
}
</style>
