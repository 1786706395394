<template>
  <span>
    <div class="container">
      <el-button class="el-button outline-btn" plain @click="onClickOptOut">{{ $t('promotion.fgp.optOut') }}</el-button>
      <el-button class="el-button blue-btn" @click="onCLickJoinAdd">{{
        $t('promotion.fgp.joinWithOtherAcc')
      }}</el-button>
    </div>
    <OptOutDialog :dialogVisible.sync="showOptOutDialog" :campaignID="campaignID" :activeAccounts="activeAccounts" />
    <OptInDialog :dialogVisible.sync="showJoinAddDialog" :campaignID="campaignID" accountModalType="optInAdd" />
  </span>
</template>

<script>
import OptOutDialog from './OptOutDialog.vue';
import OptInDialog from './OptInDialog.vue';

export default {
  components: { OptOutDialog, OptInDialog },
  props: {
    campaignID: { type: Number },
    activeAccounts: { type: Array }
  },
  data() {
    return {
      showOptOutDialog: false,
      showJoinAddDialog: false
    };
  },
  methods: {
    onClickOptOut() {
      this.showOptOutDialog = true;
    },
    onCLickJoinAdd() {
      this.showJoinAddDialog = true;
    }
  }
};
</script>

<style scoped lang="scss">
@import '@/assets/css/pages/promotion/fundsGrowthPromo/accountDialog.scss';

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

@media (max-width: 600px) {
  .long-button {
    flex-basis: 100%;
    max-width: none;
  }
}
</style>
