<template>
  <span>
    <GeneralDialog :visible.sync="isShowDialog" @close="onClose" width="540px">
      <div class="dialog-content">
        <p v-if="accountModalType === 'optInNew'" class="text-bold">
          {{ $t('promotion.fgp.dialog.selectAccountContext') }}
        </p>
        <p v-if="accountModalType === 'optInAdd'" class="text-bold">{{ $t('promotion.fgp.dialog.joinWithOther') }}</p>
      </div>
      <el-form :model="formData" :rules="rules" ref="form">
        <el-form-item :label="$t('common.field.accNum')" prop="selectedAccounts">
          <el-select
            v-model="formData.selectedAccounts"
            :placeholder="$t('promotion.fgp.dialog.selectAccPlaceholder')"
            multiple
          >
            <el-option v-for="item in accountOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <div v-if="accountModalType === 'optInNew'" class="tnc">
          <el-form-item prop="checked">
            <el-checkbox v-model="formData.checked" data-testid="tncConfirm">
              {{ $t('promotion.fgp.agreeTnc') }}
              <a :href="tncLink" target="_blank" style="color: #014abd; text-decoration: underline">{{
                $t('promotion.fgp.tncLink')
              }}</a>
            </el-checkbox>
          </el-form-item>
        </div>
        <div class="btn-wrapper">
          <el-button class="btn-blue submit-btn" @click="onSubmit">
            {{ $t('common.button.submit') }}
          </el-button>
        </div>
      </el-form>
    </GeneralDialog>

    <!-- Result Dialog -->
    <ResultDialog :dialogVisible.sync="resultDialogVisible" resultModalType="optIn" />
  </span>
</template>

<script>
import GeneralDialog from '@/components/dialog/general/Dialog.vue';
import ResultDialog from '@/components/promotion/fundsGrowthPromo/ResultDialog.vue';
import { apiGetRedeemableAccounts, apiOptInWithAccounts, apiOptOutWithAccounts } from '@/resource';

export default {
  name: 'AcountDialog',
  components: { GeneralDialog, ResultDialog },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    campaignID: {
      type: Number,
      required: true
    },
    accountModalType: {
      type: String // optInNew || optInAdd
    }
  },
  data() {
    return {
      rules: {
        selectedAccounts: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        checked: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error(this.$t('common.formValidation.tnc')));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ]
      },
      redeemableAccounts: [],
      formData: {
        selectedAccounts: [],
        checked: false
      },
      isShowDialog: false,
      resultDialogVisible: false,
      tncLink: 'https://www.puprime.com/terms-and-conditions/13-pa-funds-growth/'
    };
  },
  computed: {
    accountOptions() {
      return this.redeemableAccounts.map(({ mt4Account, currency, datasourceId, accountDisplayType }) => {
        let accountType = this.$config.accountTypeMaps[Number(accountDisplayType)] || null;
        let label = accountType ? `${mt4Account} - ${accountType} - ${currency}` : `${mt4Account} - ${currency}`;
        return {
          label,
          value: mt4Account
        };
      });
    }
  },
  watch: {
    dialogVisible: {
      immediate: true,
      handler(newValue) {
        this.isShowDialog = newValue;
      }
    }
  },
  methods: {
    showError() {
      this.$message({
        message: this.$t('common.field.failed'),
        type: 'error',
        duration: 5000
      });
    },
    onClose() {
      this.$refs.form.resetFields();
      this.$emit('update:dialogVisible', false);
    },
    getRedeembleAcc() {
      apiGetRedeemableAccounts(this.campaignID).then(resp => {
        if (resp.data.code === 0 && resp.data.data) {
          this.redeemableAccounts = resp.data.data;
        }
      });
    },
    getRequestBody(accounts) {
      const reqBody = {
        campaignId: this.campaignID,
        optIn: true,
        accountMt4DtoList: accounts.map(acc => {
          return {
            mt4Account: acc,
            datasourceId: this.redeemableAccounts.find(i => i.mt4Account === acc).datasourceId
          };
        })
      };
      return reqBody;
    },
    validateForm() {
      return new Promise(resolve => {
        this.$refs.form.validate(valid => {
          resolve(valid);
        });
      });
    },
    async onSubmit() {
      try {
        const isValid = await this.validateForm();
        if (!isValid) return;

        const accounts = this.formData.selectedAccounts;
        const reqBody = this.getRequestBody(accounts);
        console.log(reqBody);
        apiOptInWithAccounts(reqBody).then(resp => {
          if (resp.data.data) {
            this.onClose();
            this.resultDialogVisible = true;
          } else {
            this.showError();
          }
        });
      } catch (e) {
        console.error(e);
        this.showError();
      }
    }
  },
  mounted() {
    this.getRedeembleAcc();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/promotion/fundsGrowthPromo/accountDialog.scss';

.text-bold {
  font-weight: bolder;
}

/deep/.dialog.el-dialog__wrapper .el-dialog.el-dialog--center {
  width: 540px !important;
}

@media (max-width: 768px) {
  /deep/.dialog.el-dialog__wrapper .el-dialog.el-dialog--center {
    width: auto !important;
  }
}
</style>
