<template>
  <div class="meta-box">
    <div class="header">
      <div class="title">{{ $t('promotion.fgp.weeklyAchievement') }}</div>
      <div class="account-selector">
        <div class="account-number">{{ $t('common.field.accNum') }}</div>
        <el-select v-model="selectedAccount">
          <el-option
            v-for="item in activeAccounts"
            :key="item.mt4Account"
            :label="item.mt4Account"
            :value="item.mt4Account"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="box-container">
      <el-table
        :data="progressData"
        style="width: 100%"
        :header-cell-class-name="getTableHeaderClass"
        :cell-class-name="getTableCellClass"
        :summary-method="getSummaries"
        show-summary
      >
        <el-table-column prop="day" />
        <el-table-column prop="eligibleFunds" :label="$t('promotion.fgp.eligibleFunds')" align="center" />
        <el-table-column
          prop="projectedDailyInterest"
          :label="$t('promotion.fgp.projectedDailyInterest')"
          align="center"
        />
      </el-table>
      <p class="table-footer">{{ $t('promotion.fgp.tableFooter') }}</p>
    </div>
    <div class="box-container">
      <div class="title mb-3">{{ $t('promotion.fgp.requirements') }}</div>
      <div class="requirement-container black-text mb-3">
        <div class="requirement-item">
          <div>{{ $t('promotion.fgp.weeklyTradedLots') }}</div>
          <div class="requirement-item-right">
            {{ tradedLots }}/{{ weeklyLotsRequired }}
            <el-checkbox v-model="tradesAchieved" disabled />
          </div>
        </div>
        <div class="requirement-item">
          <div>{{ $t('promotion.fgp.noFundsOut') }}</div>
          <div class="requirement-item-right">
            <el-checkbox v-model="noFundOut" disabled />
          </div>
        </div>
      </div>
      <ButtonGroup :campaignID="campaignID" :activeAccounts="activeAccounts" />
    </div>
  </div>
</template>

<script>
import ButtonGroup from './ButtonGroup.vue';
export default {
  props: {
    optIn: { type: Boolean },
    activeAccounts: { type: Array },
    selectedActiveAccount: { type: Number },
    campaignID: { type: Number }
  },
  components: {
    ButtonGroup
  },
  data() {
    return {
      weeklyInterestAmount: undefined,
      tradesAchieved: undefined,
      noFundOut: true,
      tradedLots: undefined,
      weeklyLotsRequired: undefined,
      progressData: undefined,
      checked: true,
      selectedAccount: undefined
    };
  },
  watch: {
    selectedActiveAccount(acc) {
      this.selectedAccount = acc;
      this.getDailyProgress();
    },
    selectedAccount(acc) {
      this.$emit('update:selectedActiveAccount', acc);
    }
  },
  methods: {
    getDailyProgress() {
      if (this.optIn) {
        const selectedAccountInfo = this.activeAccounts.find(acc => acc.mt4Account === this.selectedActiveAccount);
        this.weeklyInterestAmount = selectedAccountInfo.totalInterest;
        this.tradesAchieved = selectedAccountInfo.tradesAchieved;
        this.noFundOut = !selectedAccountInfo.isFundOut;
        this.tradedLots = selectedAccountInfo.totalLotsTraded;
        this.weeklyLotsRequired = selectedAccountInfo.weeklyLotsRequired || 0.0;
        this.progressData = Array.from({ length: 5 }).map((_, index) => {
          const wi = selectedAccountInfo.weeklyInterest.find(i => i.dayOfWeek === index);
          return {
            day: this.$t(`promotion.fgp.days.${index}`),
            eligibleFunds: wi?.balance
              ? `$${wi?.balance?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}`
              : '-',
            projectedDailyInterest: wi?.balanceEligible === true ? `$${wi.interest}` : '-'
          };
        });
      }
    },
    getTableHeaderClass() {
      return 'blue-text';
    },
    getTableCellClass({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 0) return 'blue-text';
      return '';
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t('promotion.fgp.totalWeeklyInterest');
          return;
        }
        if (index === 2) {
          sums[index] = `$${this.weeklyInterestAmount}`;
        }
      });

      return sums;
    }
  },
  mounted() {
    this.selectedAccount = this.selectedActiveAccount;
    this.getDailyProgress();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/fgp.scss';

.title {
  font-size: 24px;
  font-weight: bold;
}

/deep/ .blue-text {
  color: #004abc !important;
  font-weight: bold;
}

.black-text {
  color: $black;
  font-weight: bold;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  flex-wrap: wrap;
  gap: 12px;

  .account-selector {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-left: auto;

    .account-number {
      white-space: nowrap;
    }
  }
}

.table-footer {
  margin: 1rem 0;
  font-size: 12px !important;
}

/deep/.el-table__footer tr td {
  color: $black;
  font-weight: bold;
  background-color: #ebf4ff;
}

.requirement-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  justify-content: space-between;
  align-items: center;

  .requirement-item {
    height: 100%;
    display: flex;
    background-color: #f3f6fb;
    border-radius: 6px;
    justify-content: space-between;
    align-items: center;
    padding: 16px 36px;
  }

  .requirement-item-right {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

/deep/ .el-checkbox__input,
/deep/ .el-checkbox {
  cursor: default;
}

/deep/.el-checkbox__input.is-disabled .el-checkbox__inner {
  background-color: $white;
  border-color: #dcdfe6;
  cursor: default;
}

/deep/.el-checkbox .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: $pug-blue-hover;
  border-color: $pug-blue-hover;
  cursor: default;
  &::after {
    border-color: $white;
    border-width: 2px;
    cursor: default;
  }
}

@media (max-width: 500px) {
  .requirement-container {
    grid-template-columns: 1fr;
  }
}
</style>
